import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="container">
      <h1>404: Not Found</h1>
      <p>Sorry, but the page you have reached does not exist.</p>
    </div>
  </Layout>
);

export default NotFoundPage;
